import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Video from "../../components/video"

const Page = () => {
  const metadata = {
    title: "Jason K. Gamsby",
    description:
      "Jason was admitted to practice law in all CT State Courts and US District Courts for CT. He is a CT Super Lawyer Rising Star and CT Bar Association's Young Lawyers Section member.",
    heroH1: "Jason K. Gamsby",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Jason K. Gamsby</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/kxMvGSfmjXI?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Jason K. Gamsby is a 2003 graduate of Sacred Heart University
                (Fairfield, CT), with a B.S. in Psychology and an A.S. in Legal
                Administration. He received his M.S. in Forensic Science with a
                concentration in Advanced Investigation in 2008 from the Henry C.
                Lee School of Forensic Science. He received his Juris Doctorate
                with a concentration in Civil Advocacy with Honors from the
                Quinnipiac University School of Law in 2013. While at Quinnipiac,
                Jason received the Arnold J. Bai Award for Excellence in
                Litigation and was featured in the Quinnipiac Magazine for his
                effective use of visual persuasion at trial.
              </p>
              <p className="mb-8">
                Jason previously was Senior Trial Attorney for Allstate Insurance
                Company from 2017-2022 and has tried over 17 cases to jury verdict
                in that time. Jason spent the first 13 years of his legal career
                working both as a paralegal and attorney at a high profile,
                catastrophic injury plaintiff’s trial firm in New Haven, CT. Prior
                to becoming an attorney, Jason was the lead trial assistant as a
                paralegal at his former firm, assisting attorneys at trials
                throughout Connecticut with digital evidence presentation in both
                State and Federal Court. While working at his prior firm, Jason
                specialized in automotive liability, premises liability, products
                liability, medical malpractice, and professional liability.
              </p>
              <p className="mb-8">
                Jason has been an Adjunct Professor in Legal Studies at the
                University of New Haven since 2019. He teaches Mock Trial and also
                coaches the Undergraduate University Mock Trial Team. Jason also
                serves as a third-year member on the University of New Haven,
                Alumni Board of Directors.
              </p>
              <p className="mb-8">
                Jason is admitted to practice law in all Connecticut State Courts
                and also the United States District Courts for the District of
                Connecticut and the Southern District of New York. During his
                involvement on the Connecticut Bar Association’s Young Lawyers
                Section, he frequently coordinated and presented at many State CLE
                programs. He was also previously recognized as a Connecticut Super
                Lawyer Rising Star in 2016 and 2017.
              </p>
              <p className="mb-8">
                Jason enjoys spending time with his wife, Lisa, daughter, Grace,
                and dog, Pesky. He enjoys Disney World, Quinnipiac University Ice
                Hockey, vacationing, coaching, and attending Grace’s sporting
                events, spending time outdoors and playing softball.
              </p>
            </div>
          </div>
          <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
            <figure style={{ height: "150px", width: "150px" }}>
              <StaticImage
                className="rounded-l-2xl"
                height={"150"}
                quality="100"
                src="../../images/atty_gamsby.jpg"
                formats={["auto", "webp", "avif"]}
                alt="Photo of Jason K. Gamsby"
                imgStyle={{
                  borderTopLeftRadius: "1rem",
                  borderBottomLeftRadius: "1rem",
                }}
              />
            </figure>
            <div class="px-4 pb-2 text-gray-900">
              <p className="text-xl font-bold">Jason K. Gamsby</p>
              <p className="">Attorney</p>
              <p className="">
                <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
              </p>
              <p className="">
                203-250-1835 <strong>fax</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
